// src/App.js
import React from 'react';
import LandingPage from './LandingPage'; // Adjust the path if needed

function App() {
  return (
    <div>
      <LandingPage />
    </div>
  );
}

export default App;
