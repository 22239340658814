import React from 'react';
import { Typography, Container, Box } from '@mui/material';
import { LinkedIn } from '@mui/icons-material';
import { IconButton, Link } from '@mui/material';
import logo from './rayvenx-elecblue.png';
import X from '@mui/icons-material/X';

function LandingPage() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        bgcolor: '#121212',
        color: '#FFFFFF',
      }}
    >``
      {/* Hero Section */}
      <Box
        sx={{
          paddingY: { xs: 4, md: 8 },
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',  // Ensures centering of the content
          flexGrow: 1,           // Allows the hero section to grow and center vertically
        }}
      >
        <Container maxWidth="md">
        <Box
            component="img"
            src={logo}
            alt="RayvenX Logo"
            sx={{
              width: {
                xs: '120px',  // Mobile screen size
                sm: '180px',  // Small screens
                md: '240px',  // Medium screens
                lg: '300px',
                xl: '400px',
              },
              height: 'auto', // Maintain aspect ratio
              maxWidth: '100%', // Prevent overflow
              display: 'block',
              margin: '0 auto',
              marginBottom: '20px',
            }}
          />
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              color: '#BB86FC',
              fontWeight: 'bold',
              fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem' },
              fontFamily: 'Exo, sans-serif', 
            }}
          >
            RayvenX
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontWeight: 'bold',
              color: '#D3D3D3',
              fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' }, // Responsive font size
              fontFamily: 'Exo, sans-serif',
            }}
          >
            Enabling Threat Informed Cyber Defense with tailored threat intelligence
          </Typography>
          <Typography
            variant="h5"
            sx={{
              textAlign: 'center',
              margin: { xs: '20px auto', md: '50px auto' },
              fontWeight: 'bold',
              color: '#BB86FC',
              fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' }, // Responsive font size
              fontFamily: 'Exo, sans-serif',
            }}
          >
            RayvenX is redefining threat intelligence by empowering organizations with tailored & actionable threat intelligence that help
            organizations proactively manage cyber security threats & risks with a threat informed approach
          </Typography>
        </Container>
      </Box>

      {/* Footer */}
      <Box
        sx={{
          paddingY: 4,
          bgcolor: '#1D1D1D',
          textAlign: 'center',
          marginTop: 'auto', // Push footer to bottom
        }}
      >
        {/* Message with Social Media Icons */}
        <Typography variant="body1" sx={{ color: '#BB86FC', mb: 2, fontFamily: 'Exo, sans-serif', }}>
          For the latest updates from RayvenX, follow us on 
          <Link href="https://x.com/RayvenXcyber" target="_blank" rel="noopener noreferrer" sx={{ color: '#7DF9FF', mx: 1 }}>
            <IconButton sx={{ p: 0, color: '#7DF9FF' }}>
              <X />
            </IconButton>
          </Link> 
          & 
          <Link href="https://www.linkedin.com/company/rayvenx" target="_blank" rel="noopener noreferrer" sx={{ color: '#7DF9FF', mx: 1 }}>
            <IconButton sx={{ p: 0, color: '#7DF9FF' }}>
              <LinkedIn />
            </IconButton>
          </Link>
        </Typography>

        <Typography variant="body2" sx={{ color: '#BB86FC', fontFamily: 'Exo, sans-serif', }}>
          © 2024 RayvenX Pte Ltd. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
}

export default LandingPage;
